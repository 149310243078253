.textDiv {
	width: 100%;
	margin: 2em 0;
}

.headerBox {
	background-color: #f5f5f5;
	padding: 1em;
	margin-bottom: 2em;
}

.title {
	font-weight: 500;
}

.row {
	display: flex;
	width: 100%;
	min-height: 150px;
	margin-bottom: 1em; /* Added margin to create space between row and subtitle */
}

@media (max-width: 600px) {
	.row {
		min-height: 100px;
		flex-direction: column;
	}
}

.column {
	flex: 1;
	transition: transform 0.2s ease-in-out;
}

.column:hover {
	transform: scale(1.05);
}

.textSubTitle {
	max-width: 800px;
	margin: 0 auto; /* Changed to 0 auto to center horizontally */
	padding: 0 1em;
	line-height: 1.6;
	color: #333;
}

@media (max-width: 600px) {
	.textDiv {
		margin: 1em 0;
	}

	.headerBox {
		padding: 0.75em;
		margin-bottom: 1em;
	}

	.textSubTitle {
		font-size: 0.9rem;
		padding: 0 1.5em;
	}

	.column {
		min-height: 80px;
	}
}
